a{
    cursor: pointer;
}


.pro_img {
    position: relative;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      height: 120px;
      width: 120px;
    }
    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }
    .enIconUploaded {
      position: absolute;
      left: 0;
      top: 0;
      .enCloseButton {
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: auto;
        padding-bottom: 7px;
        text-transform: uppercase;
        padding-top: 3px;
        border: none;
        background: #0000006b;
        color:  #ffffff;
        z-index: 9;
        opacity: 0;
        transition: all 0.2s ease-out;
      }
    }
    .edt_img_btn {
      pointer-events: none;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      color:  #ffffff;
      font-size: 12px;
      padding-top: 48px;
      background-color: #3e4ab873;
      z-index: 1;
      opacity: 0;
      transition: all 0.2s ease-out;
      .material-icons {
        font-size: 16px;
        vertical-align: top;
        margin-top: -2px;
        margin-right: 3px;
      }
    }
    &:hover {
      .edt_img_btn {
        opacity: 1;
      }
      .enCloseButton {
        opacity: 1;
      }
    }
  }

  .text-clip-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .rc-time-picker {
    width: 100%;
    .rc-time-picker-input {
      height: 36px;
      font-size: 16px;
      font-weight: bold;
    }
  }