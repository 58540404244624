.chat-day-title {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
}
.chat-day-title::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  top: 14px;
}

.chat-day-title .title {
  background-color: #fff;
  position: relative;
  padding: 6px 24px;
  font-size: 20px;
}
