.app-search {
  overflow: hidden;
  height: 70px;
  display: table;
  max-width: 100%;
  margin-right: 20px;
}
.app-search-box {
  display: table-cell;
  vertical-align: middle;
}
.app-search-box input::-webkit-input-placeholder {
  font-size: 0.8125rem;
  color: #323a46;
}
.form-control {
  border: none;
  height: 38px;
  padding-left: 20px;
  padding-right: 0;
  color: #323a46;
  background-color: #f9f9fa !important;
  box-shadow: none;
  border-radius: 30px;
  border-color: #f9f9fa;
  border: none !important;
}
.input-group-append {
  margin-left: 0;
  z-index: 4;
}

.app-search-box .btn {
  background-color: #f9f9fa;
  border-color: transparent;
  color: #6c757d;
  border-radius: 30px;
  box-shadow: none !important;
  cursor: default !important;
}

.app-search-box .btn .search-i {
  cursor: default !important;
}
