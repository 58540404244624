.dropzone-container {
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone-image-preview {
  max-height: 100px;
}

.dropzone-delete-file {
  position: absolute;
  z-index: 1;
}
.dropzone-delete-file.audio {
  position: absolute;
  z-index: 1;
  right: 0;
}
.dropzone-delete-file > i {
  cursor: pointer;
  padding: 3px 3px 0px 3px;
  font-size: 20px;
  color: #000;
}
.dropzone-delete-file > i:hover {
  background-color: #000;
  color: #fff;
}